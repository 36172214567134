import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Text } from "@chakra-ui/react";
import { useFormik } from "formik";

import { submitForm } from "../fetchers";

import Form from "../components/Form";
import FormSubmitted from "../components/FormSubmitted";

const clientReferralSchema = Yup.object().shape({
  therapistName: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 1, label: "Referring Clinician: Name" }),
  therapistEmail: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 2, label: "Referring Clinician: Email" }),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 3, label: "Patient: First name" }),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 4, label: "Patient: Last name" }),
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .meta({ order: 5, label: "Patient: Email" }),
  phone: Yup.string()
    .min(10, "Too Short!")
    .max(11, "Too Long!")
    .meta({ order: 6, label: "Patient: Phone" }),
  sexAtBirth: Yup.string()
    .required("Required")
    .oneOf(["Male", "Female"])
    .meta({
      order: 7,
      label: "Patient: Sex at birth",
      variant: "select",
      options: [
        {
          label: "Female",
          value: "Female"
        },
        {
          label: "Male",
          value: "Male"
        }
      ]
    }),
  state: Yup.string()
    .required("Required")
    .oneOf(["CA", "NY"])
    .meta({
      order: 8,
      variant: "select",
      label: "State",
      placeholder: "Select state",
      options: [
        {
          label: "California",
          value: "CA"
        },
        {
          label: "New York",
          value: "NY"
        }
      ]
    })
});

const InPatientFormPage = () => {
  const [state, setState] = useState({
    errorMsg: "",
    loading: false,
    sent: false,
    startTs: new Date().getTime()
  });
  const formik = useFormik({
    initialValues: {
      therapistName: "",
      therapistEmail: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sexAtBirth: "",
      state: ""
    },
    validationSchema: clientReferralSchema,
    onSubmit: async (values) => {
      setState((s) => ({ ...s, loading: true }));
      // alert(JSON.stringify(values, null, 2));
      const result = await submitForm({
        formId: "rl-referral",
        formVersion: "v1",
        formData: values,
        startTs: state.startTs,
        endTs: new Date().getTime()
      });
      if (result.success) {
        setState((s) => ({ ...s, sent: true }));
      } else if (result.status === 429) {
        setState((s) => ({
          ...s,
          errorMsg:
            "Too many requests from your network, please try again later"
        }));
      } else {
        setState((s) => ({
          ...s,
          errorMsg: "Something went wrong, please try again or reach out"
        }));
      }

      setState((s) => ({ ...s, loading: false }));
    }
  });

  if (state.sent) {
    return <FormSubmitted />;
  }

  return (
    <>
      <Box bg="#cee0da" w="100%" p={4} color="black">
        <Text className="rl_dm_sans" fontSize="24px">
          Refer a patient to Options MD
        </Text>
      </Box>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Text color="tomato" fontSize="lg">
          {state.errorMsg}
        </Text>
      </Box>
      <Form
        formik={formik}
        formProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }
        }}
        submitButtonLabel="Submit"
        submitButtonProps={{
          colorScheme: "blue",
          sx: {
            marginTop: 2
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 10
        }}
        submitLoading={state.loading}
        validationSchema={clientReferralSchema}
      />
      <Text margin="0 auto 32px" maxW={"400px"}>
        Upon form submission the patient will receive an email and an sms text
        message with a link to get started with registration and onboarding to
        Options MD.
      </Text>
    </>
  );
};

export default InPatientFormPage;
